<script setup lang="ts">
const { t } = useT();
</script>

<template>
	<div class="why-us">
		<AText variant="tamale" as="h3" :modifiers="['uppercase', 'center']" class="title">
			<i18n-t keypath="4 reasons why {key}">
				<template #key>
					<span class="text-caracas">{{ t("fortune wheels?") }}</span>
				</template>
			</i18n-t>
		</AText>
		<div class="reasons">
			<div class="reason">
				<NuxtImg src="/nuxt-img/why-us/reason1.webp" width="128" height="128" class="icon" alt="icon" loading="lazy" />
				<AText variant="tuzla" :modifiers="['bold']">
					{{ t("Play with Gold Coins for fun!") }}
				</AText>
			</div>
			<div class="reason">
				<NuxtImg src="/nuxt-img/why-us/reason2.webp" width="128" height="128" class="icon" alt="icon" loading="lazy" />
				<AText variant="tuzla" :modifiers="['bold']">
					{{ t("Play with Sweeps Coins!") }}
				</AText>
			</div>
			<div class="reason">
				<NuxtImg src="/nuxt-img/why-us/reason3.webp" width="128" height="128" class="icon" alt="icon" loading="lazy" />
				<AText variant="tuzla" :modifiers="['bold']">
					{{ t("Fast prize redemption!") }}
				</AText>
			</div>
			<div class="reason">
				<NuxtImg src="/nuxt-img/why-us/reason4.webp" width="128" height="128" class="icon" alt="icon" loading="lazy" />
				<AText variant="tuzla" :modifiers="['bold']">
					{{ t("US based company!") }}
				</AText>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.why-us {
	display: flex;
	flex-direction: column;
	gap: gutter(3);
	margin: gutter(3) gutter(0);

	@include media-breakpoint-down(lg) {
		margin: gutter(2.5) gutter(2);
	}
}
.reasons {
	gap: gutter(3);
	width: 100%;

	@include media-breakpoint-up(md) {
		gap: gutter(1.5);
		display: flex;
		flex-wrap: wrap;
	}
}

.reason {
	display: flex;
	align-items: center;
	position: relative;
	gap: gutter(1);
	padding: gutter(1) gutter(2);
	border-radius: 12px;
	border: 1px solid rgba(var(--cofeea-rgb), 0.3);
	box-shadow: 0 4px 10px 0 rgba(12, 12, 15, 0.15);
	background: var(--crawley);
	margin-bottom: 16px;
	overflow: hidden;

	@include media-breakpoint-up(md) {
		width: calc(50% - 6px);
	}

	@include media-breakpoint-up(xl) {
		width: calc(25% - 9px);
	}

	.icon {
		width: 70px;
		height: 70px;
		position: relative;

		&:deep(img) {
			width: 100%;
			height: auto;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		max-width: 90px;
		background: url("/nuxt-img/why-us/circle-lines.webp") no-repeat 50% 50% / cover;
		scale: 2.5;
		animation: rotate 12s linear infinite;
	}

	&:first-child {
		span {
			max-width: 180px;
		}
	}

	span {
		font-size: 20px;
		margin-left: gutter(1.5);
	}
}

.title {
	@include media-breakpoint-down(md) {
		font-size: 24px;
		line-height: 30px;

		span {
			display: block;
		}
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
